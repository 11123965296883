.download-icon-wrapper {
    width: auto;
    height: auto;
    position: relative;
}

.download-icon {
    z-index: 9999;
    position: absolute;
    bottom: 7px;
    right: 0px;
    background: $light-gray;
    opacity: 0.5;
}

.download-icon a {
    display: block;
}

.download-icon svg {
    width: 28px;
    height: 28px;
    margin: 0 auto;
    padding: 5px;
    opacity: 0.4;
}

/*Magic Zoom Styles*/
.mz-thumb.mz-thumb-selected {
    img {
        border-bottom-color: $highlight-color !important;
    }
}
.mz-expand .mz-expand-bg {
    display: none !important;
}

.mz-expand {
    background-color: #fff !important;
}

.product-hero {
    margin-top: 1.5rem;
    h1 {
        border-bottom: .2rem solid $light-gray;
        padding-bottom: .3rem;
    }

    span {
        color: $secondary-gray;
        font-size: 1rem;
        font-weight: 800;
        font-family: $font-family-condensed;
        margin-bottom: -.3rem;
        margin-left: .1rem;
        display: block;
    }
}

.product-tabs, .search-tabs {

    .tab-content {
        border-top: .2rem solid $light-gray;
        padding-top: 3rem;

        @include media-breakpoint-down(sm) {
            border-top: none;
        }
    }

    .nav-tabs {
        max-width: 80rem;
        border-bottom: none
    }

    .nav-tabs .nav-link {
        font-family: $font-family-condensed;
        font-weight: 800;
        font-size: 1.3rem;
        text-transform: uppercase;
        border: none;
        text-decoration: none;

        @include media-breakpoint-down(sm) {
            text-align: left;
            color: $header-gray;
        }
    }

    a.nav-link.active {
        border-bottom: .5rem solid $highlight-color;
        text-decoration: none;
        color: $secondary-gray;

        @include media-breakpoint-down(sm) {
            background-color: $body-color;
            color: $white;
            border-radius: 0px;
        }
    }

    a.nav-link:hover {
        color: $highlight-color;
    }
}


.grid-nav {
    a {
        text-decoration: none;
    }

    h2 {
        background-color: #e2e2e2;
        padding-left: 7px;
        padding-bottom: 5px;
        margin-bottom: 1rem;
    }

    h3 {
        color: $header-gray;
        border-bottom: 3px solid $light-gray;
        padding-bottom: .4rem;
        margin-bottom: 1rem;
        margin-left: 7px;
        font-weight: 600;
        font-size: 1.3rem !important;
        font-style: italic;
    }

    h4 {
        font-size: 1.3rem !important;
        margin-left: 10px;
    }
}


/*Downloads Tab*/

.tooltip-inner {
    background-color: $light-gray;
    padding: 7px;
}

.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before {
    border-left-color: $light-gray;
}

.featured-download {
    img {
        @include media-breakpoint-down(sm) {
            width:80px;
        }
    }
}
.downloads-category {
    padding-bottom: 7px;
    border-bottom: 3px solid $secondary-gray;
}


.downloads-table td {
    padding: .45rem;

    @include media-breakpoint-down(sm) {
        font-size: .8rem;
    }
}

.downloads-table thead th {
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
        font-size: .8rem;
    }
}

.downloads-table th {
    padding: .55rem;

    @include media-breakpoint-down(sm) {
        padding: .35rem;
    }
}

.table-hover tbody tr:hover {
    background-color: $highlight-color;
}

.document-card-span {
    font-size: .9rem;
    text-align: left;
    display: block;
    color: $secondary-gray;

    @include media-breakpoint-down(sm) {
        font-size: .75rem;
    }
}

.document-card-link {
    display: block;
    margin: 9px 0px 9px 0px;
    font-size: 1rem;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
        font-size: .75rem;
    }
}

.tooltip.show {
    opacity: 1;
}

.card-wrapper {
    font-weight: 700;
    font-family: $font-family-compressed;
    font-size: 1.2rem;
    line-height: initial;

    @include media-breakpoint-down(lg) {
        font-size: 1rem;
    }

    a {
        text-decoration: none;
    }

    .card-body {
        span {
            font-weight: 800;
        }
    }
}
.card-body-large {
    display: inline-block;

    span {
        font-weight: 700;
        font-family: $font-family-compressed;
        vertical-align: top;
        padding-top: 1rem;
    }

    p {
        @include media-breakpoint-down(sm) {
            font-size: 1rem;
            line-height: 1.3rem;
        }
    }
}

.product-icon {
    img {
        width: 150px;

        @include media-breakpoint-down(md) {
            width: 110px;
        }
    }
}

