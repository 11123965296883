$body-color: #222222;
$secondary-gray: #313131;
$light-gray: #E2E2E2;
$very-light-gray: #f7f7f7;
$header-gray: #575757;
$highlight-color: #8CC63E;
$highlight-color-light: #CAE884;
$highlight-color-dark: #4E6E25;
$white: #FFFFFF;
$navbar-color: #E2E2E2;
$navbar-dropdown-color: #f1f1f1;
$button-font-color: #222;
$footer-color: #191919;