﻿html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: $font-family-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $body-color;
    margin-bottom: 0;

    &.bodyclass {
        overflow: hidden !important;

        .togglesearch {
            z-index: 1000;
        }
    }
}

    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1600px;
        padding-left: 3rem;
        padding-right: 3rem;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            overflow-wrap: anywhere;
        }
    }

    ::-moz-selection {
        background: $highlight-color;
    }

    ::selection {
        background: $highlight-color;
    }

    .notes {
        ul {
            padding-left: 1.5rem;

            li {
                list-style: square;

                ul {

                    li {
                        list-style: circle;
                    }
                }
            }
        }
    }

    a img {
        text-decoration: none;
        border: 0 none;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: $very-light-gray;
    }

    .newsletter-wrapper {
        background-color: $header-gray;
        min-height: 203px;
        color: $white;
    }

    .newsletter-wrapper h1 {
        color: $highlight-color;
        font-family: $font-family-condensed;
        font-weight: 800;
        font-size: 3.5rem;
        margin-bottom: 0px;
    }

    .newsletter-wrapper span {
        font-size: 1.6rem;
    }

    .newsletter-wrapper input {
        height: 63px;
        border-radius: 0;
    }

    .newsletter-wrapper button {
        height: 63px;
        border-radius: 0;
        margin-top: 8px;
    }

    .newsletter-wrapper img {
        height: 54px;
        width: 54px;
    }

    .newsletter-wrapper h1 {
        font-size: 3rem;
        margin-bottom: 0px;
        margin-top: 20px;
    }

    .newsletter-wrapper span {
        font-size: 1.2rem;
    }

.footer {
    position: static;
    /*bottom: 0;*/
    width: 100%;
    /* fixed height */
    min-height: 300px;
    color: $light-gray;
    font-size: 21px;
    font-family: $font-family-condensed;
    margin-top: 2rem;

    @include media-breakpoint-down(md) {
        min-height: 415px;
    }

    a {
        color: $light-gray;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
    }

    a:hover {
        color: $highlight-color;
        transition: all 0.3s ease-in-out;
        text-decoration: underline;
    }

    span {
        display: block;
    }

    ul {
        list-style: none;
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: left;
            padding-left: 0px;
        }
    }
}

    .recall {
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: left;
        }
    }

    .social-media-icon {
        display: inline-block;
        margin: 0 10px 0 0;
    }

    .social-media-icon a {
        display: inline-block;
    }

    .social-media-icon:hover .cls-1 {
        fill: $highlight-color;
    }

    .social-media-icon svg {
        width: 50px;
        height: 50px;
    }

    .cls-1 {
        fill: #7e7e7e;
        fill-rule: evenodd;
        transition: fill 0.3s ease-in-out;
    }

    .ffs-green {
        color: $button-font-color;
        background-color: $highlight-color !important;
    }

.ffs-green:hover {
    color: $button-font-color;
    background-color: $highlight-color-light !important;
    text-decoration: none;
}

.fe-btn {
    border: none;
    border-radius: 0;
    display: inline-block;
    padding: 5px 27px 7px 27px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none !important;
    transition: background-color 0.3s ease-out;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;

    @include media-breakpoint-down(md) {
        white-space: normal;
    }
}

.ffs-btn {
    border: none;
    border-radius: 0;
    transition: background-color 0.3s ease-out;
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        margin-top: 16px;
    }

}

.ffs-btn-lg {
    padding: .45rem 1rem;
}

    .ffs-btn:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }

    .ffs-green-hr {
        border-top: 3px solid $highlight-color;
    }

    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;

        iframe {
            vborder: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .form-control:focus {
        color: #495057;
        border-color: $highlight-color;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .input-group-lg > .input-group-append > .btn {
        border-radius: 0;
    }

    .search-form {
        .text-input {
            border-radius: 0;
        }

        .btn {
            border-radius: 0;
            background-color: $highlight-color;
            font-family: $font-family-compressed;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    #cookie-consent {
        border-radius: 0;
        border-color: transparent;
        background-color: #575757;
        color: #fff;

        @include media-breakpoint-down(md) {
            font-size: .8rem;
        }

        a {
            color: #fff;
            font-weight: bold;

            &:hover {
                text-decoration: none;
            }
        }

        #cookie-consent-close {
            line-height: .8;
        }

        .close {

            &:hover {
                color: #8a8a8a;
            }
        }
    }


    .alert-ffs {
        color: $button-font-color;
        background-color: $highlight-color;
        border-color: $highlight-color-light;
        border-radius: 0;

        @include media-breakpoint-down(md) {
            font-size: .8rem;
        }

        a:hover {
            text-decoration: none;
        }
    }

.ValidationMessage {
    color: red;
}

.black-text {
    color: #222;
}

.white-text {
    color: $white;
}
