﻿#resultTable {

    th {
        font-size: 27px;
        font-weight: 700;
        font-family: $font-family-compressed;
    }

    tr.contact-row {
        td {
            border-top: 20px solid #fff;
            padding: 7px 10px 10px 10px;
            background-color: $header-gray;
            color: $white;

            &:first-child {
                font-weight: 700;
                font-size: 17px;
            }
        }
    }

    table td, table#resultTable table th {
        border-bottom: 0px;
        padding: 2px;
    }
}
 

table#resultTable, table#resultTable table {
    margin-bottom: 20px;
}

.CertifiedSearchButtons div {
    display: inline-block;
    margin-top: 20px;
}

.CertifiedSearchFields {
    margin-bottom: 30px;

    input {
        margin-bottom: 7px;
    }
}

.certifications {
    margin-bottom: 1rem;

    span {
        font-weight: 700;
    }
}

.installer-contact {
    margin-bottom: .5rem;
    background-color: $navbar-dropdown-color;
    margin-top: -8px;
    padding-left: 10px;
    padding-right: 5px;
    margin-left: -10px;
    margin-right: -10px;

    span {
        font-weight: 700;
    }
}

.cert-row {
    padding-right: 12px;

    div {
        line-height: 1.25rem;
        padding-bottom: 8px;
    }
}