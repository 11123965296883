﻿
.search-tabs {

    .nav-tabs {
        max-width: none !important;
        border-bottom: none
    }

    .nav-link {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .tab-content {
        padding-top: 1rem;
    }

    .result-title {
        font-size: 1.2rem;
        font-family: $font-family-condensed;
        font-weight: 800;
    }


    .sku-result-container {
        display: inline-block;
        margin: 5px 0px;

        a {
            float: right;
        }

        .part-title {
            font-size: .85rem;
            color: #575757;
        }
    }

    .sku-number {
        background-color: $highlight-color;
        padding: 2px 5px;
        white-space: nowrap;
    }

    .sku-result {
        border: 1px solid $highlight-color;
        padding-right: 5px;
        margin-bottom: 4px;
    }

    .page-link {
        font-size: 1.5rem;
        color: inherit !important;
        text-decoration: none;
        background-color: transparent;
        border-color: transparent;
        font-family: $font-family-condensed;
        font-weight: 800;
    }

    .page-item.active .page-link {
        color: #fff !important;
        background-color: #575757;
        border-color: #575757;
    }

    .sku-desc {
        width: 100%;
        padding: 2px 3px;
    }
}

#SearchForm .togglesearch-text {
    height: auto;
    line-height: 34px;
}

