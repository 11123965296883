.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}
#main {
    width:100%;
    .toggle-container{
        margin-top: -3px;
    }

    a.dt-toggle-nav, a.toggle-nav {
        text-decoration: none;
        font-size: 0.8rem;
        margin-bottom: 7px;
        display:inline-block;
        svg {
            margin-right: 0.2rem;
            margin-top: -.2rem;
        }
    }
}

#sidebar {
    max-width: 336px;
    min-width: 336px;
    margin-right: 30px;

    &.active {
        @include media-breakpoint-down(md) {
            margin-left: 0px;
        }
    }

    @include media-breakpoint-down(lg) {
        max-width: 250px;
        min-width: 250px;
    }

    @include media-breakpoint-down(md) {
        margin-left: -250px;
        margin-right: 0px;
    }
}


#sidebar {
        &.show {
        /*transition: all 1s ease-in-out;*/
    }
}

#sidebar li a {
    white-space: normal;
    word-wrap: break-word;
}

#expand-menu-col {
    max-width: 70px;
}

/* change transition animation to width when entire sidebar is toggled */
#sidebar.collapse {
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}
#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}


#sidebar ul li a:hover {
    background: $highlight-color;
    color: #000000;
}

#sidebar ul li div:hover {
    background: $highlight-color;
}


a[data-toggle="collapse"] {
    position: relative;
}

#sidebar .dropdown-toggle::after {
    display: none;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

#sidebar a {
    text-decoration: none !important;
}

#sidebar ul li a {
    display: inline-block;
    margin-right: 25px;
    width: 100%;
}

#sidebar ul li a:hover {
    color: #000000;
}


#sidebar .active {
    border-bottom: 5px $highlight-color solid;
    font-family: $font-family-condensed;
    font-weight: 800;
    font-style: italic;
    padding-bottom: 3px !important;
}

#sidebar ul li ul lh {
    line-height: 1.4;
    cursor: default;
    display: block;
    margin-top: 7px;
    padding-left: 10px;
}

#sidebar ul li ul li {
    padding-left: 7px;
}



/*-----------------------------------------------------------------------------------------
	NEW
	----------------------------------------------------------------------------------------*/

#sidebar {
    padding: 0;
}

#productNav {
    padding: 0;
    margin-bottom: 1.1rem;
}

.silo {
    font-family: $font-family-condensed;
    font-weight: 700;
    font-size: 21px;
    border-top: $light-gray 2px solid;

    &:first-child {
        border-top: none;
    }

    &:last-child {
        border-bottom: $light-gray 2px solid;

        &.silo-active {
            border-bottom: none;
        }
    }

    &.silo-active {
        margin-bottom: 1rem;
    }

    .silo-link-div a {
        font-weight: 800;
    }

    a {
        padding: 10px 8px 10px 8px;
    }

    .silo-active {
        margin-bottom: 1rem;
    }

    .silo-link-div-active {
        margin-bottom: 7px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 18px;
    }
}




.product-category {
    font-family: $font-family-condensed;
    font-weight: 700;
    font-size: 18px;


    @include media-breakpoint-down(lg) {
        font-size: 15px;
    }
}

.product-category a {
    padding: 5px;
}

.product-series-wrapper {
    background-color: $light-gray;
    color: $header-gray;
    padding-left: .4rem;
    padding-bottom: .1rem;
    width: 93% !important;
}

.product-series-wrapper:hover {
    background-color: $light-gray !important;
}

.product-series {
    font-family: $font-family-condensed;
    font-weight: 700;
    font-style: italic;
    font-size: 16px;
    color: $header-gray;


    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }
}

.product-series a {
    padding: 5px;
}

.product-tertiary-wrapper {
    background-color: $light-gray;
    color: $header-gray;
    padding-left: .4rem;
    padding-bottom: .1rem;
    width: 93% !important;
}

.product-tertiary-wrapper:hover {
    background-color: $light-gray !important;
}

.product-tertiary {
    font-family: $font-family-condensed;
    font-weight: 700;
    font-style: italic;
    font-size: 16px;
    color: $header-gray;


    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }
}

.product-tertiary a {
    padding: 5px;
}

.product-page {
    font-family: $font-family-body;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;


    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }
}

.treeview {
    padding-left: 0px;
    margin-bottom: 0rem;
}

/*-----------------------------------------------------------------------------------------
	PREVIOUS PRODUCT NAVIGATION TREE MENU STYLE
	----------------------------------------------------------------------------------------*/
.treeview ul { /*CSS for Simple Tree Menu*/
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    cursor: pointer !important;
}

.treeview li { /*Style for LI elements in general (excludes an LI that contains sub lists)*/
    background-repeat: no-repeat;
    list-style-type: none;
    list-style-image: none;
    line-height: 17px;
}


.treeview li div {
    width: 100%;
}

.treeview li div:hover {
}

.link-wrapper {
    width: 93% !important;

    @include media-breakpoint-down(lg) {
        width: 91% !important;
    }
}
.link-wrapper:hover {
    background: $highlight-color;
}

.treeview li.submenu { /* Style for LI that contains sub lists (other ULs). */
    background-position: 5px 10px;
    margin-right: 5px;
    cursor: pointer !important;
}

.treeview .clicker-div {
    background-image: url(/assets/images/ToggleNorm.png);
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    float: right;
    background-size: 100%;
}

.treeview li.submenu ul li.productLink:hover {

}

*html .treeview li.submenu { /* Style for LI that contains sub lists (other ULs). */
    margin-right: 5px;
    /*cursor: hand !important;*/
    cursor: pointer !important;
}

*:first-child + html .treeview li.submenu {
    background-position: 0px 7px;
}

#IETableFix td {
}

*html #IETableFix td {
    height: 200px;
}

.productLink a {
    display: block;
}

.treeview li.submenu ul { /*Style for ULs that are children of LIs (submenu) */
    display: none; /*Hide them by default. Don't delete. */
}

.treeview .submenu ul li { /*Style for LIs of ULs that are children of LIs (submenu) */
    cursor: default;
    padding-left: 12px;
    list-style-type: none;
    /*	
	border-bottom:dotted;
	border-bottom-width:1px;
	border-bottom-color:#AAAAAA;
*/
}

.treeview .submenu ul li a.hover { /*Style for LIs of ULs that are children of LIs (submenu) */
    cursor: default;
    padding-left: 10px;
    text-decoration: underline;
}

/* added for franklin-fueling */
.localNavSub {
}

/* links without headers */
.treeview .submenu > ul > li > a {
    display: block;
}


.treeview .submenu ul li div:hover {
    background-color: transparent;
    cursor: auto;
}



.collapse.show {
    visibility: visible;
}

#sidebar {
    overflow: hidden;
    z-index: 3;
}

#sidebar .list-group {
    min-width: 400px;
    background-color: #333;
    min-height: 100vh;
}

