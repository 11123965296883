﻿.logo {
    svg {
        width: 300px;
        @include media-breakpoint-down(sm) {
            width: 160px;
        }
    }   
}

.footer {
   background-color: $footer-color;
}

a.navbar-brand {
    @include media-breakpoint-down(sm) {
        padding-top: 0px
    }
}