﻿table {
    &.specifications {
        tbody {
            td:first-child {
                font-weight: bold;
                background-color: #042233;
                color: #fff;
            }
        }
    }

    &.RTEtable {
        &.pim-table thead tr {
            color: #fff;
            background-color: #343a40;
            border-color: #454d55;
        }
    }
}
