﻿.salesperson-card {
    height: 100%;
    padding: 17px;
    background-color: $very-light-gray;
    h5 {
        font-weight: bold;
    }
    h6 {
        font-weight: bold;
    }
}