.nav-button {
    position: relative;
    z-index: 1111;
    background-color: $light-gray;
    height: 42px;
    display: block;
    width: 50px;
    padding: 12px;
}

.nav-button #nav-icon3 {
    width: 24px;
    height: 22px;
    display: inline-block;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.nav-button #nav-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 26px;
    background: $secondary-gray;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out
}

.nav-button #nav-icon3 span:nth-child(1) {
    top: 0
}

.nav-button #nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
    top: 7px
}

.nav-button #nav-icon3 span:nth-child(4) {
    top: 14px
}

.nav-open #nav-icon3 span:nth-child(1) {
    top: 9px;
    width: 0;
    left: 50%
}

.nav-open #nav-icon3 span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.nav-open #nav-icon3 span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.nav-open #nav-icon3 span:nth-child(4) {
    top: 9px;
    width: 0;
    left: 50%
}

.nav-open .main-menu {
    opacity: 1;
    visibility: visible;
    background: rgba(0, 0, 0, 0.92);
    height: 100%;
    font-family: $font-family-condensed;
}

.main-menu .nav li a {
    color: $white;
    font-size: 2.8rem;
    font-weight: 600;
    text-decoration: none;

    @include media-breakpoint-down(sm) {
        font-size: 2rem;
    }
}

.main-menu .nav li {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-animation-timing-function: cubic-bezier(.45,.005,0,1);
    -moz-animation-timing-function: cubic-bezier(.45,.005,0,1);
    -o-animation-timing-function: cubic-bezier(.45,.005,0,1);
    animation-timing-function: cubic-bezier(.45,.005,0,1);
}

.nav-open .main-menu .nav li {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: -webkit-transform .2s,opacity .2s;
    transition: transform .2s,opacity .2s;
}

.fixed-top {
    display: none;
}

.nav-open .fixed-top {
    display: block;
    overflow: scroll;

    .fe-btn {
        height: 100%;
        border-radius: 0;
        color: $body-color;
    }
}

.tiptop-navbar {
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 0.8rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
    background-color: $white;
    padding-left: 0rem;
    padding-right: 0rem;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .fe-btn {
        padding: 2px 25px 3px 25px;
        margin-top: 1px;
        margin-bottom: 3px;
    }

    ul {
        display: flex;
        flex-direction: row;
        margin-bottom: 0rem;
        padding-left: 0rem;
        align-self: center;
        
        li {
            display: list-item;
            padding-top: 0rem;
            font-size: .9rem;
            color: #949494;
            margin-right: 1rem;
            list-style-image: none;
            list-style-position: outside;
            list-style-type: none;

            a {
                color: #949494;
            }
        }
    }
}

.abs-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

nav.sticky-top {
    margin-bottom: 2rem;
}

.global-navbar {
    background: $navbar-color;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.nav .fe-btn {

}

.global-navbar button {
    font-family: $font-family-compressed;
    font-weight: 700;
    z-index: 999;
    text-transform: uppercase;
}

.global-navbar #navbarSupportedContent {
}

.global-navbar .nav-search-icon {
    width: 49px;
    margin-left: 9px;
}

.bg-white {
    background-color: $white !important;
}

.global-nav li::after {
    content: '';
    float: right;
    right: -2px;
    width: 2px;
    height: 65%;
    margin-top: 8px;
    background: $highlight-color;
    -ms-transform: rotate(20deg); /* IE 9 */
    transform: rotate(20deg);
    margin-left:1px;
    margin-right: 2px;
}

.global-nav li:last-child::after {
    content: none;
    display: none;

}

.dropdown-menu li::after {
    content: none;
}

.dropdown-menu li:last-child::after {
    display: none;
}



.global-nav-link {
    font-family: $font-family-condensed;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
    color: $header-gray !important;
   
    @include media-breakpoint-down(lg){
        font-size:1.3rem;
    }
}

.global-nav .nav-link {
    padding: 0;
}

.global-nav-link {
    padding: 10px 20px 11px 20px !important;
    display: inline;

    @include media-breakpoint-up(xl) {
        padding: 10px 30px 11px 29px !important;
    }
}

.global-nav-link:hover, .global-nav-link:active, .global-nav li:hover {
    font-style: italic;
    font-weight: 800;
    letter-spacing: -.046rem;
}

/*----Global Nav - Desktop----*/
/* adds some margin below the link sets  */
.navbar .dropdown-menu div[class*="col"] {
    margin-bottom: 1rem;
}

.navbar .dropdown-menu {
    background-color: $navbar-dropdown-color;
    width: 100%;
    top: 52;
    left: 0;
    right: 0;
    /*  height of nav-item  */
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    text-transform: none;
    margin: 0;
    border-radius: 0;
    border: none;


    @include media-breakpoint-down(lg) {
        top: 47px;
    }
}


.persona-col:not(:last-child) {

}

.persona {

    span {
        clear: both;
        display: block;

        @include media-breakpoint-down(lg) {
            font-size: .9rem;
        }
    }

    h2 {
        text-transform: uppercase;
        margin-bottom: .2rem;

        @include media-breakpoint-down(lg) {
            font-size: 1.3rem;
        }
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    img {
        margin-top: 10px;
    }

    .persona-icon {
        max-width: 150px;
        margin-top: -10px;
    }

    .persona-col {
        float: left;
        padding-left: 36px;
        border-right: 3px solid $light-gray;

        img {
            max-height: 36px;
        }
    }

    .persona-slat {

        img {
            margin-top: 0px;
            max-width: 110px;
            margin-right: 10px;
        }
    }

    .persona-col:nth-child(3) {
        border-right: none;
    }
}

.navbar {
    .dropdown {
        position: static;
    }


    .dropdown-menu.list-menu a, .dropdown-menu.product-menu a, .link-list-landing-page .link-list-block a{
        text-decoration: none;
        font-weight: 600;
        FONT-FAMILY: $font-family-condensed;
        font-size: 1.3rem;

        &:hover {
            text-decoration: underline;
        }

        @include media-breakpoint-down(lg) {
            font-size: 1.14rem;
        }
    }


    .dropdown-menu {
        border: none;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;

        a {
            text-decoration: none;
        }

        p {
            font-family: $font-family-condensed;
        }

        h3 {
            font-weight: 800;
            font-style: italic;
            font-size: 2rem;
            text-transform: uppercase;
        }

        h5 {
            font-weight: 800;
            font-family: $font-family-compressed;
        }

        .product-categories {
            list-style: none;
            padding-left: 0px;
            margin-top: 1rem;
            margin-left: 0px;
        }

        .product-categories li {
            padding-left: 0px !important;
            padding-top: 0px !important;
        }

        .product-categories {

            .nav-item {
                padding: 10px;
                text-transform: none;

                &:hover {
                    font-style: normal;
                    font-weight: normal;
                    letter-spacing: normal;
                }

                img {
                    float: left;
                    width: 42px;
                    margin-right: 3px;
                    margin-bottom: 5px;
                }

                span {
                    margin-left: 50px;
                    display: block;
                    line-height: 1.4rem;
                    padding-top: 8px;
                }
            }
        }

        ul.link-list-block {
            li {
                margin-bottom: 1rem;

                &:hover {
                    font-style: normal;
                    letter-spacing: initial;
                }
            }
        }
    }
}



/* remove bootstrap dropwdown caret */
.global-nav-link::after {
    display: none;
}
/* shows the dropdown menu on hover */
.navbar .dropdown:hover .dropdown-menu, .navbar .dropdown .dropdown-menu:hover {
    display: block;
    visibility: visible;
    opacity: 1;
    padding-top: 30px;
    transition: visibility 0s, opacity 0.3s linear;
}

.get-a-quote-btn {

        width: 150px;
        height: 100px;
        margin: 0 0 0 -20px;
        -webkit-transform: skew(20deg);
        -moz-transform: skew(20deg);
        -o-transform: skew(20deg);
        background: red;
        overflow: hidden;
        position: relative;
    }

.center {
    text-align: center;
}

div.skewed-heading-content {
    -ms-transform: skew(20deg);
    -webkit-transform: skew(20deg);
    transform: skew(20deg);
    position: relative;
    right: -50px;
    background-color: $highlight-color;
    padding: 5px 30px 5px 0;
}

div.skewed-heading-bg {
    background-color: $highlight-color;
    display: inline-block;
    margin-right: 48px;
    padding: 0px 20px 0px 0px;
    -ms-transform: skew(-20deg,0);
    -webkit-transform: skew(-20deg,0);
    transform: skew(-20deg,0);
}

#shape {
    height: 0;
    width: auto;
    border-bottom: 58px solid $highlight-color;
    border-left: 20px solid transparent;
    padding: 0 20px 0 20px;
}


.skew-button a {
    border: none;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;

}

.skew-button {
    background: $highlight-color;
    display: block;
    color: $button-font-color;
    font-weight: normal;
    padding: 10px;
    position: relative;
    text-decoration: none;
    transition: background 0.25s;
    -webkit-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;
    min-width: 155px;
}

.skew-button.triangle:after {
    content: "";
    background-color: $white;
    display: block;
    height: 100%;
    position: absolute;
    left: -10px;
    top: 0;
    width: 22px;
    -webkit-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-15deg);
}


.search {
    width: 22px;
    height: 22px;
    margin-top: -4px;
}

.search-icon-link {
    padding: 10px 30px 12px 25px !important;
}

.search-icon-link:hover .search-icon {
    fill: $secondary-gray;
}

.search-icon {
    fill: #575757;
}

.search-li {
    position: relative;
}

.search-li:hover {
    font-style: normal !important;
    font-weight: 400 !important;
    letter-spacing: 0rem !important;
}

.togglesearch {
    display: none;
    padding-top: 30px;
    padding-bottom: 25px;
    position: absolute;
    border: none;
    background-color: $navbar-dropdown-color;
    width: 100%;
    top: 52px;
    left: 0;
    right: 0;
    text-transform: none;
    margin: 0;
    border-radius: 0;
    z-index: 999;
}

.togglesearch-text {
    outline: none;
    border-radius: 0rem;
}
.togglesearch-text:focus {
    border-color: $highlight-color;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.togglesearch-btn {
    font-family: $font-family-compressed;
    font-weight: 700;
    font-weight: 700;
    color: $button-font-color;
    text-transform: uppercase;
    background-color: $highlight-color;
    border: none;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    border-radius: 0rem;
    padding-bottom: 10px;
}

.togglesearch-btn:hover {
    color: $button-font-color;
    background-color: $highlight-color-light;
}

.togglesearch-btn:focus {
    color: $body-color;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.page-link:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

// IE10+ CSS here
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .togglesearch .form-group {width:400px;}
}