﻿
h1, h2 {
    font-family: $font-family-compressed;
}

h3 {
    font-family: $font-family-condensed;
    font-weight: 800;
}

h4 {
    font-family: $font-family-condensed;
    font-weight: 800;
}


a {
    color: $secondary-gray;
    text-decoration: underline;
}

a:hover {
    color: $secondary-gray;
    text-decoration: underline;
    transition: color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
}
